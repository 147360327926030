<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.fromDate.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.toDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Employee</label><Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.employeeID.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.ipNotCdi.val"> IP not CDI</label>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js';
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import DatePicker from "@/components/utils/DatePicker";
export default {
    name: 'Options',
    components: { Typeahead, DatePicker },
    data() {
        return {
            state: store.state,
            cache: {
                cdiLocationCache,
            },
        }
    }
}
</script>

<style scoped>
</style>